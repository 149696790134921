import il8n from '@/assets/language/index.js';
const { t } = il8n.global; 

export const tableOption = {
  border: true,
  height: 'auto',
//   stripe: true,
  menuAlign: 'center',
  align: 'center',
  refreshBtn: false,
  showClomnuBtn: false,
  searchMenuSpan: 6,
  searchLabelWidth: '130',
  labelWidth: '160',
  columnBtn: false,
  addBtn: false,
  editBtn: false,
  delBtn: false,
  menu:false,
  column: [{
    width: 160,
    label: t("adminDict.name2"),
    prop: 'dictType',
    placeholder: t("inputTit.shuru"),
    editDisabled: true,
  }, {
    label: t("currencySet.name1"),
    prop: 'tenantName',
    span: 24,
    // 'search': true,
},{
    width: 170,
    label: t("adminDict.name3"),
    prop: 'description',
    placeholder: t("inputTit.shuru"),
  }, {
    label: t("adminDict.name4"),
    prop: 'systemFlag',
  }, {
    label: t("adminDict.name5"),
    prop: 'remarks',
    placeholder: t("inputTit.shuru"),
  }, {
    label: t("adminDict.name6"),
    prop: 'createTime',
  },{
    label: t("button.operation"),
    prop: 'caozuo',
    placeholder: t("inputTit.shuru"),
    fixed:'right',
  },]
}

export const tableDictItemOption = {
  border: true,
  height: 300,
  stripe: true,
  menuAlign: 'center',
  align: 'center',
  refreshBtn: false,
  showClomnuBtn: false,
  columnBtn: false,
  addBtn: false,
  editBtn: false,
  delBtn: false,
  menuWidth: 140,
    menu:false,
  column: [{
    label: t("adminDict.name2"),
    prop: 'dictType',
    addDisabled: true,
    editDisabled: true
  }, {
    width: 120,
    label: t("adminDict.name7"),
    prop: 'value',
  }, {
    width: 120,
    label: t("adminDict.name8"),
    prop: 'label',
  }, {
    width: 120,
    label: t("adminDict.name3"),
    prop: 'description',
  }, {
    width: 140,
    label: t("button.operation"),
    prop: 'caozuo',
    fixed:'right',
  },]
}
