<template>
    <div class="execution">
        <avue-crud ref="crudItem" :page="page"
            :data="page.list" :table-loading="listLoading"
            @size-change="sizeChange" :option="tableDictItemOption1"
            @current-change="currentChange">
            <template #menu-left>
                <div class="meunButton">
                    <el-button v-if="sys_dict_add" type="primary" 
                        @click="openView(0)" >{{$t("button.add")}} </el-button>
                </div>
            </template>
            <template #caozuo="scope" >
                 <el-button v-if="sys_dict_add" text
                    type="primary" size="small"  @click="openView(1,scope.row)">{{$t("button.update")}}
                </el-button>
                 <el-button v-if="sys_dict_add" text
                    type="primary" size="small" @click="rowDel(scope.row)">{{$t("button.del")}}
                </el-button>
            </template>
        </avue-crud>    
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isAdd]' width="600" lock-scroll>
            <bindView ref='bindView_' @successFun='successFun_' @closeFun='dialogFormVisible = false'/>
            <template #footer>
                <el-button   @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"  @click="completeFun(form_)">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
    import { ref,defineExpose,getCurrentInstance,nextTick } from 'vue'
    import {tableDictItemOption} from '@/const/crud/dict'
    // import Api_ from '@/api/index'
    import bindView from './InfoBind.vue'
    // import { ElNotification,ElMessageBox } from 'element-plus'
    const { $t } = getCurrentInstance().proxy;

    const form = ref(null)
    const isStyleText = [$t("adminDict.text1"),$t("adminDict.text2")]
    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['getDictListItem',['delDictitem','id']])
    const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,rowDel,updateOtherParm} = pageObj
    
    const sys_dict_add = ref(true)
    const bindView_ = ref(null)
    const isAdd = ref(0)
    const tableDictItemOption1 = ref(null);tableDictItemOption1.value = tableDictItemOption

    const successFun_ = ()=>{
        getList()
        dialogFormVisible.value = false
    }
    const openView = (bool,parm)=>{
        isAdd.value = bool
        dialogFormVisible.value = true
        nextTick(()=>{
            if(parm){
                bindView_.value.init_(bool,parm)
            }else{
                bindView_.value.init_(bool,form.value)
            }
        })
    }
    const init_ = (parm)=>{
        let obj = {
            dictType: parm.dictType,
            dictId: parm.id,
        }
        form.value = parm
        updateOtherParm(obj)
        getList()
    }
    const completeFun = ()=>{
        bindView_.value.completeFun()
    }
    defineExpose({init_})
</script>

<style lang="scss" scoped>
</style>

